<template>
  <div>
    <div v-if="$can('read', 'Report')">
        <b-table
            bordered
            class="mt-2"
            head-variant="light"
            hover
            :items="reports_data"
            :fields="reports_setting.fields"
            responsive
            small
            OLDstacked=false
            stacked="sm"
        >
            <template #cell(actions)="row">
                <b-button
                    size="sm"
                    variant="primary"
                    @click="showReport(row.item)"
                >
                    Zobrazit
                </b-button>
            </template>
        </b-table>
    </div>
  </div>
</template>

<script>
import { state, mutations } from '@/store/index.js'
import { defaultsReport } from "@/store/report"
import { deepCopy } from '@/lib/gms/utils.js'

export default {
    components: { },

    computed: {
        reports_data( ) {
            return state.reports_data
        }
    },

    created( ) { },

    data( ) {
        return {
            // https://getbootstrap.com/docs/4.6/utilities/text/
            
            reports_setting: {
                fields: [
                    {
                        key: "nazev",
                        label: "Název",
                        tdClass: ["text-left", "font-weight-bold", "bg-light"],
                        thClass: "bg-light"
                    },
                    {
                        key: "popis",
                        label: "Popis",
                        tdClass: ["text-left"],
                        thClass: "bg-light"
                    },
                    {
                        key: "actions",
                        label: "Akce",
                        tdClass: ["text-center", "fw-bold"],
                        thClass: "bg-light"
                    }
                ]
            }
        }
    },

    methods: {
        showReport(report) {
            mutations.setReportId(report.klic)

            this.$router.push({ name: "report", params: {  } })
        }
    },

    mounted( ) {
        mutations.setReportId(null)

        mutations.setReportParams(deepCopy(defaultsReport.report_params))

        mutations.setReportResult(deepCopy(defaultsReport.report_result))

        mutations.setReportSetting(deepCopy(defaultsReport.report_setting))

        mutations.readReports( )
    }
}
</script>